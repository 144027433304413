import { urls } from "~/api/config";
import type {
  IStoreSearchAddressBody,
  IStoreSearchAddressResponse,
  IStoreSearchGeoBody,
  IStoreSearchGeoResponse,
  IStoreSearchStoreResponse,
} from "~/typings/api/storeSearch";

export default () => {
  const fetchAddressSearch = (body: Partial<IStoreSearchAddressBody> = {}) => {
    const { $transport } = useNuxtApp();
    return $transport<IStoreSearchAddressResponse>(
      urls["store-search"].address,
      { body, method: "POST" },
    );
  };
  const fetchGeoSearch = (body: Partial<IStoreSearchGeoBody> = {}) => {
    const { $transport } = useNuxtApp();
    return $transport<IStoreSearchGeoResponse>(urls["store-search"].geo, {
      body,
      method: "POST",
    });
  };
  const fetchStoreByCode = (code: string) => {
    const { $transport } = useNuxtApp();
    return $transport<IStoreSearchStoreResponse>(
      `${urls["store-search"].store}/${code}`,
    );
  };

  return {
    fetchAddressSearch,
    fetchGeoSearch,
    fetchStoreByCode,
  };
};
