import {
  ShopSelectShopType,
  ShopSelectShopTypeNumeric,
} from "@magnit/unit-shop-select/src/enums";
import type { IStoreSearchStore } from "~/typings/api/storeSearch";

export const STORE_DEFAULT_VALUE = {
  storeType: "DG",
  name: "Тиффани",
  code: "942311",
  city: "Краснодар",
  address: "Краснодарский край, г. Краснодар, ул. им. Максима Горького, д. 112",
  latitude: 45.0306,
  longitude: 38.9763,
  openingHours: "08:30",
  closingHours: "21:00",
  pickup: false,
  pickupWorkFrom: "",
  pickupWorkTill: "",
  isPricecheckerAvailable: false,
  isPricesAndStockAvailable: true,
  isFavourite: false,
  delivery: false,
  timezone: "",
  house: "112",
  index: "350000",
  okrug: "Южный округ",
  region: "Краснодарский край",
  street: "им. Максима Горького ул",
  subjectRegion: "Краснодарский край",
  storeFormat: "МК/Мегаполис МК",
  storeFormatCode: "mk",
  onlineAvailable: false,
  isDistributorPickupActive: false,
  distributorWarehouseId: "",
  room: "",
  cityFiasId: "",
  coordinates: {
    latitude: 45.0306,
    longitude: 38.9763,
  },
} as IStoreSearchStore;
export const STORE_DEFAULT_STORE_ID = "13163";
export const STORE_DEFAULT_STORE_CODE = STORE_DEFAULT_VALUE.code;

export const STORE_ALLOWED_SHOP_TYPES = [ShopSelectShopType.Cosmetic];
export const STORE_ALLOWED_SHOP_TYPES_NUMERIC = [
  ShopSelectShopTypeNumeric.Cosmetic,
];
export const STORE_SEARCH_STRING_MIN_LENGTH = 3;
export const STORE_SEARCH_STRING_DEBOUNCE = 500;
export const STORE_MAP_MIN_ZOOM = 8;
